import React from 'react';
import { Link, graphql } from 'gatsby';

import SiteWrapper from '../components/site-wrapper';
import Section from '../primitives/section';
import Layout from '../primitives/layout';

export default function Privacy({ data }) {
  return (
    <SiteWrapper>
      <Layout>
        <Section width="medium">
          <h1>Privacy Statement</h1>
          <p>
            <strong>Effective: November 26, 2014</strong>
          </p>
          <p>
            This Privacy Policy is a supplement to and is hereby incorporated by
            reference into LifeLoop’s Terms of Use, as modified from time to
            time and located at{' '}
            <Link to="/privacy">
              {data.site.siteMetadata.displayUrl}/privacy
            </Link>
            . This Privacy Policy governs the manner in which LifeLoop, LLC
            (“LifeLoop,” “we,” “us,” or “our”) collects, uses, maintains, and
            discloses information collected from users (“you,” “your” or “User”)
            of its solutions,{' '}
            <Link to="/">{data.site.siteMetadata.displayUrl}</Link> web site and
            any derivative or affiliated web sites on which this Privacy Policy
            is posted (collectively, the “Site”). LifeLoop reserves the right,
            in its sole discretion, to change, modify, add, or remove portions
            of this Privacy Policy at any time by posting such changes to this
            page. You understand that you have the affirmative obligation to
            check this Privacy Policy periodically for changes, and you hereby
            agree to periodically review this Privacy Policy for such changes.
            The continued use of the Site following the posting of changes to
            this Privacy Policy constitutes your acceptance of those changes.
          </p>
          <p>
            <strong>Privacy</strong>
          </p>
          <p>
            LifeLoop is committed to safeguarding the information you entrust to
            LifeLoop and believes that every User should know how it utilizes
            the information collected from Users. Please note that the Site may
            contain links to other web sites, and the Site allows Users to
            download or otherwise access Content (including software),
            controlled by third parties. LifeLoop is not responsible for the
            privacy practices of these or any other web sites or any Content
            controlled by third parties, and you access and utilize such Content
            and web sites entirely at your own risk. LifeLoop recommends that
            you review the privacy and other practices governing any other web
            sites that you choose to visit and any Content you wish to download
            or otherwise access.
          </p>
          <p>
            LifeLoop is based, and this web site is hosted, in the United States
            of America. If you are from the European Union, Canada, or other
            regions of the world with laws governing data collection and use
            that may differ from U.S. law and you are registering an account on
            the Site, visiting the Site, or otherwise using the Site, please
            note that any personally identifiable information that you provide
            to LifeLoop may be transferred to or utilized by LifeLoop or its
            affiliates in the United States. Any such personally identifiable
            information provided may be processed and stored in the United
            States by LifeLoop or a service provider acting on its behalf.
          </p>
          <p>
            <strong>The Information LifeLoop Collects</strong>
          </p>
          <p>
            Users may provide and LifeLoop may collect personally identifiable
            information from Users in a variety of ways. Personally identifiable
            information may include, without limitation: (i)&nbsp;contact data
            (such as a User’s name, mailing and e-mail addresses);
            (ii)&nbsp;demographic data (such as a User’s zip code or age); or
            (iii) other information requested during the account registration
            process. If you communicate with LifeLoop by e-mail or otherwise,
            post User Content to the Site, post messages to any forums, complete
            online forms, surveys or entries, provide information outside of the
            account registration process, or otherwise interact with or uses the
            features on the Site, any information you provide may be collected
            by LifeLoop.
          </p>
          <p>
            LifeLoop may also collect information about how Users use the Site,
            for example, by tracking the number of unique views received by the
            pages of the Site, or the domains and Internet Protocol (“IP”)
            addresses from which Users originate. While not all of the
            information that LifeLoop collects from Users is personally
            identifiable, it may be associated with personally identifiable
            information that Users provide LifeLoop through the Site or
            otherwise. Additionally, certain information regarding your computer
            systems, network locations, IP address, and software may be
            communicated to LifeLoop in certain circumstances by the digital
            rights management systems associated with certain Content that you
            download or access via the Site in order to prevent unauthorized use
            or distribution of the associated Content. Non-personally
            identifiable information collected by LifeLoop (such as patterns of
            utilization) is exclusively owned by LifeLoop and may be used as it
            sees fit in accordance with applicable law.
          </p>
          <p>
            <underline>Cookies</underline>
          </p>
          <p>
            Like most web sites, the Site uses cookies and web log files to
            track site usage. A cookie is a tiny data file that resides on your
            computer that allows LifeLoop to recognize you as a User when you
            return to the Site using the same computer and web browser. Like the
            information you enter during account registration, cookies and log
            file data are used to customize your experience on the Site. One
            type of cookie, known as a “persistent” cookie, is set once you have
            logged in to your Site account. The next time you visit our Site,
            the persistent cookie will allow us to recognize you as an existing
            User so you will not need to log in before using the Site. In order
            to access or change any of your private information, you must log in
            with your password even if your computer contains your persistent
            cookie. Additionally, if you log out of the Site you will need to
            re-enter your password the next time you visit in order to log in to
            your account. Another type of cookie, called a “session” cookie, is
            used to identify a particular visit to the Site. Session cookies
            expire after a short time or when you close your web browser. You
            can choose to disable cookies on your browser or allow your browser
            to notify you when a cookie is being sent, however this may prevent
            you from utilizing certain features of the Site.
          </p>
          <p>
            <underline>IP Addresses</underline>
          </p>
          <p>
            Due to the communications standards on the Internet, we
            automatically receive the uniform resource locator (“URL”) of the
            web site from which you came and the web site to which you proceed
            once you leave the Site. We also receive the IP address of your
            computer (or the proxy server you use to access the World Wide Web),
            your computer operating system and type of web browser you are
            using, as well as the name of your Internet service provider
            (“ISP”). This information is used to analyze overall trends to help
            us improve the Site. The linkage between your IP address and your
            personally identifiable information is shared with third parties in
            accordance with this Privacy Policy.
          </p>
          <p>
            <underline>Web Beacons and Ad Networks</underline>
          </p>
          <p>
            We may allow other companies, called ad networks, to serve
            advertisements to Users via the Site. Ad networks include third
            party ad servers, ad agencies, ad technology vendors and research
            firms. LifeLoop may “target” some ads to Users that fit a certain
            general profile. To deliver these ads properly, the Site may include
            a file, called a web beacon, from these ad networks within pages
            served by the Site or implement other monitoring technologies so the
            networks may provide anonymous, aggregated auditing, research and
            reporting for advertisers as well as to target ads to you while you
            visit other web sites. These general targets may also be used by ad
            networks to display targeted ads on other web sites as a substitute
            for random, irrelevant ads. In addition, the Site may use the
            technology of ad networks to serve ads only when you visit this
            Site. Ad networks may use non-personal information, such as your
            state or zip code, in order to provide advertisements about goods
            and services that may be of particular interest to you while you
            visit this Site. Because your web browser must request these
            advertisements and web beacons from the ad network’s servers, these
            companies can view, edit or set their own cookies, just as if you
            had requested a web page from their site.
          </p>
          <p>
            <strong>How LifeLoop Uses Information</strong>
          </p>
          <p>
            LifeLoop may use personally identifiable information collected
            through the Site for the specific purposes for which the information
            was collected, to contact Users regarding Content, advertise
            products or services offered by LifeLoop, or otherwise to enhance
            Users’ experience with LifeLoop and/or the Site. LifeLoop may also
            use information collected through the Site for research regarding
            the effectiveness of the Site and the business planning, marketing,
            advertising, and sales efforts of LifeLoop, its trusted affiliates,
            independent contractors, and business partners. Information
            collected from you may also be used to ensure compliance with
            certain terms, conditions, and agreements entered between you and
            LifeLoop as well as to prevent unauthorized use or disclosure of
            Content downloaded or otherwise accessed via the Site.
          </p>
          <p>
            LifeLoop will communicate with you through email and notices posted
            on this Site. These may include a series of welcome emails which
            help inform new Users about various features of the Site, emails,
            confirmations regarding actions taken by you on the Site, and
            notices relating to the proper functioning of the Site or your
            account. LifeLoop may use your personal information to send you
            promotional information relating to the Content, products, and
            services offered by third parties that LifeLoop feels may be of
            interest to you. The personal information you provide will allow us
            to alert you to new developments, new products, features,
            enhancements, special offers, upgrade opportunities, surveys,
            contests, and events of interest. We strive to limit use of the
            information you provide to those offers that we think you would
            appreciate receiving. To opt out of further communications from a
            marketing partner or sponsor with whom your information has been
            shared, please contact that partner directly. We may send LifeLoop
            emails that may contain information regarding third party products
            or services; however, this doesn’t mean that we’ve traded your
            personal information to any party.
          </p>
          <p>
            <strong>Disclosure of Information</strong>
          </p>
          <p>
            LifeLoop may disclose personally identifiable information collected
            from Users to its parent, subsidiary, and other related companies,
            trusted affiliates, independent contractors, and business partners
            who will use the information for the purposes outlined above, as
            necessary to provide the services offered by the Site and the Site
            itself, and for the specific purposes for which the information was
            collected. LifeLoop may disclose personally identifiable information
            at the request of law enforcement or governmental agencies or in
            response to subpoenas, court orders, or other legal process, to
            establish, protect or exercise LifeLoop’s legal or other rights, to
            defend against a legal claim or as otherwise allowed by law.
            LifeLoop may disclose personally identifiable information in order
            to protect the rights, property, or safety of a User or any other
            person. LifeLoop may disclose personally identifiable information to
            investigate or prevent a violation by User of any contractual or
            other relationship with LifeLoop or where LifeLoop has a good faith
            belief that access, preservation, or disclosure of such information
            is reasonably necessary to protect the rights, property, or safety
            of LifeLoop, its users or the public or the perpetration of any
            illegal or harmful activity. LifeLoop may also disclose aggregate,
            anonymous data based on information collected from Users to Content
            developers, investors, advertisers, and potential partners. Finally,
            LifeLoop may disclose or transfer personally identifiable
            information collected from Users in connection with or in
            contemplation of a sale of its assets or business or a merger,
            consolidation, or other reorganization of its business.
          </p>
          <p>
            <strong>
              Personal Information Provided as Part of User Content
            </strong>
          </p>
          <p>
            If a User includes such User’s personally identifiable information
            as part of the User Content that such User posts to the Site, such
            information may be made available to any parties using the Site
            and/or such User Content. LifeLoop does not edit or otherwise remove
            such information from User Content before it is posted on the Site.
            If a User does not wish to have such User’s personally identifiable
            information made available in this manner, such User must remove any
            such information from the User Content before posting. User contact
            information (which may include, without limitation, a User’s
            username and email address) may be included with any User Content
            that a User posts via the Site. LifeLoop is not liable for any
            damages caused or incurred due to personally identifiable
            information made available in the foregoing manners.
          </p>
          <p>
            <strong>Security of Information</strong>
          </p>
          <p>
            Information about Users that is maintained on LifeLoop’s systems is
            protected using industry standard security measures. In order to
            secure your personal information, access to your data is
            password-protected, and sensitive data is protected by [SSL
            encryption] when it is exchanged between your web browser and the
            Site. However, no security measures are perfect or impenetrable, and
            LifeLoop cannot guarantee that the information submitted to,
            maintained on, or transmitted from its systems will be completely
            secure. LifeLoop is not responsible for the circumvention of any
            privacy settings or security measures contained on the Site by any
            Users or third parties.
          </p>
          <p>
            <strong>Children's privacy</strong>
          </p>
          <p>
            Although our website is a general audience site, we restrict the use
            of our service to individuals age 18 and above. We do not knowingly
            collect personal information from children under the age of 13. If
            you believe we have information about a child under 13 through
            improper means, please contact us at{' '}
            <Link to="/support">Lifeloop Support</Link>.
          </p>
          <p>
            <strong>Updating Personal Information; Opt-Out</strong>
          </p>
          <p>
            If your personally identifiable information changes, LifeLoop will
            endeavor to provide a way to correct, update, and/or remove your
            previously provided personal data. This can be done by contacting us
            at <Link to="/support">Lifeloop Support</Link>. When you sign up for
            our updates or receive e-mail messages from LifeLoop, you will
            always be given the chance to opt-out of special offers and site
            updates. You may change your preferences at any time and will only
            be contacted in manners consistent with your stated preferences. All
            retained personally identifiable information will continue to be
            subject to the terms of the Privacy Policy to which you have
            previously agreed.
          </p>
          <p>
            <strong>Your California Privacy Rights</strong>
          </p>
          <p>
            California Civil Code Section 1798.83 permits Users that are
            residents of California to request certain information regarding
            LifeLoop’s disclosures of personally identifiable information to
            third parties for such third parties’ direct marketing purposes. If
            you are a California resident and would like to make such a request,
            please contact us at <Link to="/support">Lifeloop Support</Link>.
          </p>
          <p>
            <strong>
              Correcting, Updating, Accessing or Removing Personal Information
            </strong>
          </p>
          <p>
            To the extent applicable, if a User’s personally identifiable
            information changes (such as a User’s zip code), or if a User no
            longer desires to receive non-account specific information from
            LifeLoop, LifeLoop will endeavor to provide a way to correct, update
            and/or remove that User’s previously-provided personal data. This
            can be done by contacting us at{' '}
            <Link to="/support">Lifeloop Support</Link>. Additionally, you may
            request access to the personally identifiable information as
            collected by LifeLoop by sending a request to LifeLoop as set forth
            above. Please note that in certain circumstances, LifeLoop may not
            be able to completely remove a User’s information from its systems.
            For example, LifeLoop may retain a User’s personal information for
            legitimate business purposes, if it may be necessary to prevent
            fraud or future abuse, for account recovery purposes, if required by
            law or as retained in LifeLoop’s data backup systems or cached or
            archived pages. All retained personally identifiable information
            will continue to be subject to the terms of the Privacy Policy to
            which the User has previously agreed.
          </p>
          <p>
            <strong>Contacting LIFELOOP</strong>
          </p>
          <p>
            If you have any questions or comments about this Privacy Policy, you
            may contact LifeLoop via any of the following methods:
          </p>
          <p>
            LifeLoop, LLC
            <br />
            11421 Davenport Street
            <br />
            Omaha, Nebraska 68154
            <br />
            <Link to="/support">Lifeloop Contact</Link>
          </p>
        </Section>
      </Layout>
    </SiteWrapper>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        displayUrl
      }
    }
  }
`;
